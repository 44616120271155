.wyswyg {
	overflow: hidden;
	color: $color__dark;

	hr {
		background-color: $color__dark;
		border: 0;
		height: 1px;
		margin-bottom: 1.5em;
	}


	img {
		height: auto; /* Make sure images are scaled correctly. */
		max-width: 100%; /* Adhere to container width. */
	}

	figure {
		margin: 1em 0; /* Extra wide images within figure tags don't overflow the content area. */
	}

	p,
	span {
		font-family: $font-primary;
		font-size: 13px;
		color: $color__dark;
		line-height: 18px;
	}

	p {
		&+ p,
		&+ img {
			margin-top: 8px;
		}
	}

	a {
		color: $color__fire;

		&[href^="mailto"] {
			display: inline-block;

			&:after {
				position: relative;
				margin-left: 4px;
				width: 10px;
				height: 10px;
				display: inline-block;
				background-image: url("assets/images/mailto.png");
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				content: '';
			}
		}
		//font-family: $font-pt-bold;
		//text-decoration: underline;
        //
		//&:hover {
		//	color: $color-ocean--deep;
		//}
	}

	@media (--laptop) {
		p {
			&+ p,
			&+ img {
				margin-top: 20px;
			}
		}
	}

	@import "copy";

	@import "lists";

	@import "tables";

	@import "headings";
}