.wrapper {
  @include container;
  position: relative;
}

a {
  text-decoration: none;
  transition: color 0.2s linear;
}

img {
  max-width: 100%;
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

/*fixed footer by flex*/
body {
  min-height: 100vh;
  display: flex;
  font-family: $font-primary;
  flex-direction: column;
  background: url('assets/images/bg.png') repeat;

  &.is-opened {
    &:after {
      @include absolute-position;
      position: fixed;
      z-index: 999;
      background-color: $color__dark;
      opacity: 0.3;
      content: "";
    }
  }

  @media (--tablet) {
    &.is-opened {
      &:after {
        display: none;
      }
    }
  }
}

.template__content {
  flex: 1;
}


.template {
  &__box {
    padding-bottom: 40px;
  }

  &__main {
    padding: 30px 0 0;
  }

  @media (--tablet) {
    &__box {
      padding-bottom: 64px;
      display: flex;
    }

    &__main {
      width: 540px;
    }
  }
}

.sidebar {
  &--left {
    .menu {
      display: none;
    }
  }

  @media (--tablet) {
    &--left {
      .menu {
        display: block;
      }
    }

    &--right {
      width: 140px;
    }
  }
}

.title {
  margin-bottom: 8px;
  padding-bottom: 20px;
  border-bottom: 1px solid $color__silver;
  color: $color__silver;
  font-size: 16px;
  font-style: italic;

  a {
    color: $color__silver;
  }
}

.members-list {
  margin-top: 30px;
  max-width: 140px;

  &__link {
    display: inline-block;
  }

  &__item {
    display: block;

    & + .members-list__item {
      margin-top: 20px;
    }
  }
}

.members {
  &__item {
    padding: 20px 0;
    border-top: 1px solid $color__stone;
    display: flex;

    &:first-of-type {
      border-top: none;
    }
  }

  &__image {
    width: 160px;
  }

  &__box {
    margin-left: 10px;
    flex: 1;
  }

  &__title {
    a {
      color: $color__dark;
      font-size: 13px;
      font-weight: 700;
      line-height: 18px;
    }
  }

  &__content {
    p {
      color: $color__dark;
      font-size: 13px;
      line-height: 18px;
    }
  }
}