h1, h2, h3, h4, h5, h6 {
	padding-bottom: 8px;
	font-family: $font-primary;
	font-weight: 700;
	color: $color__dark;
}

h1 {
	font-size: 32px;
	line-height: 40px;
}

h2 {
	font-size: 26px;
	line-height: 32px;
}

h3 {
	font-size: 20px;
	line-height: 24px;
}

h4 {
	font-size: 18px;
	line-height: 24px;
}

h5 {
	font-size: 16px;
	line-height: 24px;
}

div, p, ul {
	&+ h1,
	&+ h2,
	&+ h3,
	&+ h4,
	&+ h5 {
		margin-top: 16px;
	}
}

@media (--laptop) {
	h1, h2, h3, h4, h5, h6 {
		padding-bottom: 16px;
	}

	h1 {
		font-size: 32px;
		line-height: 46px;
	}

	h2 {
		font-size: 26px;
		line-height: 32px;
	}

	h3, h4 {
		font-size: 20px;
		line-height: 24px;
	}

	h5 {
		font-size: 18px;
		line-height: 24px;
	}

	div, p, ul {
		&+ h1,
		&+ h2,
		&+ h3,
		&+ h4,
		&+ h5 {
			margin-top: 40px;
		}
	}
}