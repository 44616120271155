.article {
  padding: 0 20px;

  &__main {
    margin-left: 10px;
  }

  &__box {
    padding-top: 20px;
    display: flex;
  }

  &__content {
    &--documents {
      text-align: justify;
    }
  }

  &__date {
    padding-top: 10px;
    border-top: 1px solid $color__silver;
    color: $color__dark;
    font-size: 13px;
    line-height: 18px;
  }

  &__single-title {
    padding-bottom: 20px;
    color: $color__dark;
    font-size: 21px;
    line-height: 28px;
  }

  &__single-image {
    img {
      display: block;
    }
  }

  &__subtitle {
    color: $color__dark;
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
  }

  &__contacts {
    margin-top: 8px;
    text-align: center;
  }

  &__map {
    margin-top: 60px;
  }

  &__news {
    &-item {
      padding: 10px 0;
      color: $color__dark;
      font-size: 13px;
      line-height: 18px;

      strong {
        font-weight: 700;
      }

      a {
        color: $color__fire;
      }

      & + .article__news-item {
        border-top: 1px solid $color__silver;
      }
    }
  }

  &__group {
    &-title {
      margin-top: 24px;
      color: $color__dark;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }

    & + .article__group {
      .article__group-title {
        margin-top: 48px;
      }
    }

    &-list {
      margin-top: 48px;
    }

    &-item {
      display: flex;
      align-items: center;

      & + .article__group-item {
        margin-top: 48px;
      }
    }

    &-image {
      width: 136px;
    }

    &-content {
      margin-left: 10px;
      flex: 1;

      h5,
      p {
        color: $color__dark;
        font-size: 13px;
        line-height: 18px;
      }

      h5 {
        font-weight: 600;

        & + p {
          margin-top: 20px;
        }
      }
    }
  }

  &__map {
    position: relative;
    width: 100%;
    height: 360px;
  }

  &--member {
    .article {
      &__main {
        margin-left: 0;
      }

      &__image {
        max-width: 170px;
      }
    }
  }

  @media (--laptop) {
    padding: 0;
  }
}

.page-jaunumi {
  .article__title {
    margin-bottom: 0;
  }
}

.page-template-current-event-template {
  .article__title {
    padding-bottom: 8px;
  }
}