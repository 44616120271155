.menu {
  position: relative;

  &__item {
    border-bottom: 1px solid $color__gray;

    &.current-menu-item {
      .menu__link {
        background: url(assets/images/menu_active.png) repeat;
        font-weight: 700;
      }

      &.menu-item-has-children {
        .menu__submenu {
          display: block;
        }
      }
    }

    &.menu-item-has-children {
      .menu__submenu {
        display: none;
      }
    }

    &.current-menu-parent {
      .menu__link {
        background: url(assets/images/menu_active.png) repeat;
        font-weight: 700;
      }

      .menu__submenu {
        display: block;
      }
    }
  }

  &__link {
    font-size: 16px;

    &:hover {
      font-weight: 700;
    }
  }

  &__submenu {
    &-link {
      &--active {
        background: url(assets/images/menu_active.png) repeat;
        font-weight: 700;
      }
    }
    li {
      border-bottom: 1px solid #6f6f6f;

      a {
        padding-left: 20px;
      }
    }

    a {
      font-size: 12px;

      &:hover {
        font-weight: 700;
      }
    }
  }

  li {
    background-color: $color__dark;

    &:hover {
      & > a {
        background: url(assets/images/menu_active.png) repeat;
      }
    }
  }

  a {
    padding: 7px 10px 7px 27px;
    display: block;
    color: $color__white;
  }

  @media (--tablet) {
    width: 220px;

    &:before {
      position: relative;
      width: 100%;
      height: 94px;
      display: block;
      background-color: $color__dark;
      content: '';
    }

    &:after {
      position: relative;
      width: 100%;
      height: 66px;
      display: block;
      background: url('assets/images/sidebar_bottom.png') no-repeat center;
      background-size: contain;
      content: '';
    }
  }
}