.pagination {
  &__list {
    display: flex;
    justify-content: center;
  }

  &__item {
    a {
      padding: 0.5em;
      display: inline-block;
      color: $color__fire;
      font-size: 13px;
      line-height: 18px;
    }

    &--disabled {
      display: none;
    }

    &--current {
      a {
        color: $color__dark;
        font-weight: 700;
      }
    }

    & + .pagination__item {
      margin-left: 24px;
    }
  }
}