$max-width: 960px;

@mixin clearfix {
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}

@mixin outer-container ($local-max-width) {
  @include clearfix;
  width: $local-max-width;
  margin: 0 auto;
}

@mixin container {
  @include outer-container (94%);
  transition: width .2s ease-in-out;

  @media (--tablet){
    @include outer-container (96%);
  }

  @media (--laptop){
    @include outer-container ($max-width);
  }
}

@mixin browser-placeholder($color) {
  &::-webkit-input-placeholder { color:$color; }
  &:-moz-placeholder { color:$color; } /* FF 4-18 */
  &::-moz-placeholder { color:$color; } /* FF 19+ */
  &:-ms-input-placeholder { color:$color; } /* IE 10+ */
}


@mixin absolute-position {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}