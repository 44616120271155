.header {
  position: relative;
  z-index: 1000;
  width: 100%;
  height: 70px;
  background-color: $color__dark;

  &__box {
    position: relative;
    height: 70px;
  }

  &__link {
    width: 118px;
    display: block;
  }

  &__logo {
    font-size: 0;
    line-height: 0;

    img {
      display: block;
    }
  }

  &__drop {
    position: absolute;
    width: 100%;
    top: 100%;
    max-height: 0;
    overflow: hidden;
    display: block;
    transition: max-height 0.3s ease-out;

    &--active {
      max-height: 600px;
    }
  }

  &__burger {
    position: absolute;
    top: 50%;
    right: 0;
    width: 100%;
    height: 5px;
    border-radius: 8px;
    background-color: $color__white;
    opacity: 1;
    transform: translateY(-50%);
    transition: all .3s ease-in-out;

    &:before,
    &:after {
      position: absolute;
      width: 32px;
      height: 5px;
      border-radius: 8px;
      display: block;
      background-color: $color__white;
      content: "";
      transition: all .3s ease-in-out;
    }

    &:before {
      transform: translateY(10px);
    }

    &:after {
      transform: translateY(-10px);
    }

    &-box {
      position: relative;
      width: 32px;
      height: 28px;
      display: inline-block;
    }

    &-button {
      position: absolute;
      right: 0;
      top: 50%;
      width: 32px;
      height: 28px;
      cursor: pointer;
      transform: translateY(-50%);
    }

    &--open {
      .header__burger {
        background-color: transparent;

        &:before {
          top: 0;
          transform: rotate(45deg) translateY(0);
        }

        &:after {
          bottom: 0;
          transform: rotate(-45deg) translateY(0);
        }
      }
    }
  }

  @media (--tablet) {
    &__link {
      width: initial;
    }

    &__logo {
      position: absolute;
      top: 0;
      left: 0;

      img {
        width: 210px;
        height: 173px;
      }
    }

    &__drop,
    &__burger-button {
      display: none;
    }
  }
}