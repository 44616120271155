/*
 * Theme Name: DNKA
 * Description: DNKA theme
 * Author: Turn.lv
 * Text Domain: dnka-theme
*/


@import "reset";
@import "variables";
@import "fonts";
@import "grid-settings";
@import "mixins";
@import "shame";

@import "wyswyg/_elements";

@import "../../views/header";
@import "../../views/footer";
@import "../../views/menu";
@import "../../views/page";
@import "../../views/partial/pagination";

// fix for specific browsers
@import "browser-fix";