.footer {
  &__content {
    padding: 20px 0;
    border-top: 1px solid $color__silver;
    color: $color__stone;
    font-size: 13px;
    line-height: 18px;
  }

  &__title {
    text-transform: uppercase;
  }

  @media (--tablet) {
    &__box {
      margin: 0 auto;
      width: 460px;
      display: flex;
    }

    &__text {
      margin-left: 20px;
    }
  }
}