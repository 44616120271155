$font-primary: 'Open Sans', sans-serif;

@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/open-sans/opensans-italic-webfont.woff2') format('woff2'),
  url('assets/fonts/open-sans/opensans-italic-webfont.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/open-sans/opensans-light-webfont.woff2') format('woff2'),
  url('assets/fonts/open-sans/opensans-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/open-sans/opensans-semibold-webfont.woff2') format('woff2'),
  url('assets/fonts/open-sans/opensans-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/open-sans/opensans-bold-webfont.woff2') format('woff2'),
  url('assets/fonts/open-sans/opensans-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/open-sans/opensans-regular-webfont.woff2') format('woff2'),
  url('assets/fonts/open-sans/opensans-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}