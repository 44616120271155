/*
 * Theme Name: DNKA
 * Description: DNKA theme
 * Author: Turn.lv
 * Text Domain: dnka-theme
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

@font-face {
	font-family: 'Open Sans';
	src: url("assets/fonts/open-sans/opensans-italic-webfont.woff2") format("woff2"), url("assets/fonts/open-sans/opensans-italic-webfont.woff") format("woff");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Open Sans';
	src: url("assets/fonts/open-sans/opensans-light-webfont.woff2") format("woff2"), url("assets/fonts/open-sans/opensans-light-webfont.woff") format("woff");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url("assets/fonts/open-sans/opensans-semibold-webfont.woff2") format("woff2"), url("assets/fonts/open-sans/opensans-semibold-webfont.woff") format("woff");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url("assets/fonts/open-sans/opensans-bold-webfont.woff2") format("woff2"), url("assets/fonts/open-sans/opensans-bold-webfont.woff") format("woff");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url("assets/fonts/open-sans/opensans-regular-webfont.woff2") format("woff2"), url("assets/fonts/open-sans/opensans-regular-webfont.woff") format("woff");
	font-weight: 400;
	font-style: normal;
}

@custom-media --tablet (min-width: 600px);
@custom-media --laptop (min-width: 1024px);
@lost gutter 16px;
.wrapper {
	width: 94%;
	margin: 0 auto;
	transition: width .2s ease-in-out;
	position: relative;
}

.wrapper::after {
	clear: both;
	content: "";
	display: table;
}

@media (--tablet) {
	.wrapper {
		width: 96%;
		margin: 0 auto;
	}
	.wrapper::after {
		clear: both;
		content: "";
		display: table;
	}
}

@media (--laptop) {
	.wrapper {
		width: 960px;
		margin: 0 auto;
	}
	.wrapper::after {
		clear: both;
		content: "";
		display: table;
	}
}

a {
	text-decoration: none;
	transition: color 0.2s linear;
}

img {
	max-width: 100%;
}

html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

/*fixed footer by flex*/
body {
	min-height: 100vh;
	display: flex;
	font-family: "Open Sans", sans-serif;
	flex-direction: column;
	background: url("assets/images/bg.png") repeat;
}

body.is-opened:after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	position: fixed;
	z-index: 999;
	background-color: #414142;
	opacity: 0.3;
	content: "";
}

@media (--tablet) {
	body.is-opened:after {
		display: none;
	}
}

.template__content {
	flex: 1;
}

.template__box {
	padding-bottom: 40px;
}

.template__main {
	padding: 30px 0 0;
}

@media (--tablet) {
	.template__box {
		padding-bottom: 64px;
		display: flex;
	}
	.template__main {
		width: 540px;
	}
}

.sidebar--left .menu {
	display: none;
}

@media (--tablet) {
	.sidebar--left .menu {
		display: block;
	}
	.sidebar--right {
		width: 140px;
	}
}

.title {
	margin-bottom: 8px;
	padding-bottom: 20px;
	border-bottom: 1px solid #A7A7A7;
	color: #A7A7A7;
	font-size: 16px;
	font-style: italic;
}

.title a {
	color: #A7A7A7;
}

.members-list {
	margin-top: 30px;
	max-width: 140px;
}

.members-list__link {
	display: inline-block;
}

.members-list__item {
	display: block;
}

.members-list__item + .members-list__item {
	margin-top: 20px;
}

.members__item {
	padding: 20px 0;
	border-top: 1px solid #9e9e9e;
	display: flex;
}

.members__item:first-of-type {
	border-top: none;
}

.members__image {
	width: 160px;
}

.members__box {
	margin-left: 10px;
	flex: 1;
}

.members__title a {
	color: #414142;
	font-size: 13px;
	font-weight: 700;
	line-height: 18px;
}

.members__content p {
	color: #414142;
	font-size: 13px;
	line-height: 18px;
}

.wyswyg {
	overflow: hidden;
	color: #414142;
}

.wyswyg hr {
	background-color: #414142;
	border: 0;
	height: 1px;
	margin-bottom: 1.5em;
}

.wyswyg img {
	height: auto;
	/* Make sure images are scaled correctly. */
	max-width: 100%;
	/* Adhere to container width. */
}

.wyswyg figure {
	margin: 1em 0;
	/* Extra wide images within figure tags don't overflow the content area. */
}

.wyswyg p,
.wyswyg span {
	font-family: "Open Sans", sans-serif;
	font-size: 13px;
	color: #414142;
	line-height: 18px;
}

.wyswyg p + p,
.wyswyg p + img {
	margin-top: 8px;
}

.wyswyg a {
	color: #f71a22;
}

.wyswyg a[href^="mailto"] {
	display: inline-block;
}

.wyswyg a[href^="mailto"]:after {
	position: relative;
	margin-left: 4px;
	width: 10px;
	height: 10px;
	display: inline-block;
	background-image: url("assets/images/mailto.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	content: '';
}

@media (--laptop) {
	.wyswyg p + p,
	.wyswyg p + img {
		margin-top: 20px;
	}
}

.wyswyg dfn, .wyswyg cite, .wyswyg em, .wyswyg i {
	font-style: italic;
}

.wyswyg strong, .wyswyg b {
	font-weight: 600;
}

.wyswyg blockquote, .wyswyg q {
	position: relative;
	margin: 20px 0;
	padding: 6px 0 6px 22px;
	display: block;
	font-weight: 700;
}

.wyswyg blockquote p, .wyswyg q p {
	font-size: 13px;
	font-weight: 400;
	line-height: 18px;
}

.wyswyg blockquote:before, .wyswyg blockquote:after, .wyswyg q:before, .wyswyg q:after {
	content: "";
}

.wyswyg blockquote:before, .wyswyg q:before {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 6px;
	display: block;
	border-radius: 44px;
	background-color: #414142;
}

.wyswyg address {
	margin: 0 0 1.5em;
}

.wyswyg pre {
	font-size: 90%;
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 1.6em;
}

.wyswyg code, .wyswyg kbd, .wyswyg tt, .wyswyg var {
	font-size: 90%;
}

.wyswyg abbr, .wyswyg acronym {
	border-bottom: 1px dotted #414142;
	cursor: help;
}

.wyswyg mark, .wyswyg ins {
	background: #414142;
	color: #FFFFFF;
	text-decoration: none;
}

.wyswyg big {
	font-size: 125%;
}

.wyswyg sub, .wyswyg sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

.wyswyg sup {
	top: -0.5em;
}

.wyswyg sub {
	bottom: -0.25em;
}

.wyswyg .embedwrapper {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	width: 100%;
	overflow: hidden;
	background: gray;
}

.wyswyg .embedwrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@media (--laptop) {
	.wyswyg blockquote,
	.wyswyg q,
	.wyswyg address {
		font-size: 22px;
		line-height: 32px;
	}
}

.wyswyg ul, .wyswyg ol {
	position: relative;
	margin: 16px 0 16px 1.2em;
}

.wyswyg ul li, .wyswyg ol li {
	position: relative;
	color: #414142;
	font-family: "Open Sans", sans-serif;
	font-size: 13px;
	line-height: 18px;
}

.wyswyg ul li:before, .wyswyg ol li:before {
	position: absolute;
}

.wyswyg ul li:last-of-type, .wyswyg ol li:last-of-type {
	margin-bottom: 0;
}

.wyswyg ul ol, .wyswyg ol ol {
	margin: 8px 0 8px 24px;
}

.wyswyg ul {
	list-style: disc;
}

.wyswyg ul li:before {
	left: -24px;
}

.wyswyg ol {
	list-style: none;
}

.wyswyg ol li:before {
	left: -18px;
}

.wyswyg ol,
.wyswyg ul > ol {
	counter-reset: section;
	/* Creates a new instance of the section counter with each ol element */
	list-style-type: none;
}

.wyswyg ol li:before,
.wyswyg ul > ol li:before {
	counter-increment: section;
	/* Increments only this instance of the section counter */
	content: counters(section, ".") ".";
	/* Combines the values of all instances of the section counter, separated by a period */
	width: 16px;
	font-weight: 700;
	color: #414142;
}

.wyswyg li > ul,
.wyswyg li > ol {
	margin-bottom: 0;
}

.wyswyg li > ul {
	margin-left: 34px;
}

.wyswyg li > ol {
	margin-left: 34px;
}

.wyswyg li > ol li:before {
	left: -24px;
}

.wyswyg dt, .wyswyg dd {
	font-family: "Open Sans", sans-serif;
	color: #414142;
	line-height: 18px;
}

.wyswyg dt {
	padding-bottom: 8px;
	font-size: 16px;
}

.wyswyg dd {
	padding-bottom: 16px;
	font-size: 13px;
}

.wyswyg .wyswyg__table {
	padding-top: 16px;
	margin-bottom: 24px;
	overflow-x: auto;
}

.wyswyg table {
	font-family: "Open Sans", sans-serif;
	table-layout: fixed;
}

.wyswyg thead th {
	border-bottom: 3px solid #414142;
}

.wyswyg thead th,
.wyswyg thead td,
.wyswyg tfoot th,
.wyswyg tfoot td {
	padding: 0 0 8px;
	color: #414142;
	font-weight: 700;
	text-align: left;
}

.wyswyg tbody th,
.wyswyg tbody td {
	border-bottom: 1px solid #414142;
}

.wyswyg tbody th + th,
.wyswyg tbody th + td,
.wyswyg tbody td + th,
.wyswyg tbody td + td {
	border-left: 1px solid #414142;
}

.wyswyg tbody tr:last-of-type td {
	border-bottom: 3px solid #414142;
}

.wyswyg th,
.wyswyg td {
	padding: 16px 16px 16px 0;
	min-width: 188px;
	font-size: 13px;
	color: #414142;
	line-height: 18px;
}

.wyswyg th + th,
.wyswyg th + td,
.wyswyg td + th,
.wyswyg td + td {
	padding: 16px 16px 16px 10px;
}

.wyswyg th strong,
.wyswyg td strong {
	color: #414142;
}

.wyswyg tr {
	background-color: transparent;
	transition: background-color 0.2s linear;
}

.wyswyg tr:hover {
	background-color: #f7f7f7;
}

@media (--laptop) {
	.wyswyg thead th,
	.wyswyg thead td,
	.wyswyg tfoot th,
	.wyswyg tfoot td {
		font-size: 22px;
	}
	.wyswyg th,
	.wyswyg td {
		padding: 26px 26px 26px 0;
		font-size: 20px;
		letter-spacing: 0.4px;
		line-height: 30px;
	}
	.wyswyg th + th,
	.wyswyg th + td,
	.wyswyg td + th,
	.wyswyg td + td {
		padding: 26px 26px 26px 22px;
	}
}

.wyswyg h1, .wyswyg h2, .wyswyg h3, .wyswyg h4, .wyswyg h5, .wyswyg h6 {
	padding-bottom: 8px;
	font-family: "Open Sans", sans-serif;
	font-weight: 700;
	color: #414142;
}

.wyswyg h1 {
	font-size: 32px;
	line-height: 40px;
}

.wyswyg h2 {
	font-size: 26px;
	line-height: 32px;
}

.wyswyg h3 {
	font-size: 20px;
	line-height: 24px;
}

.wyswyg h4 {
	font-size: 18px;
	line-height: 24px;
}

.wyswyg h5 {
	font-size: 16px;
	line-height: 24px;
}

.wyswyg div + h1,
.wyswyg div + h2,
.wyswyg div + h3,
.wyswyg div + h4,
.wyswyg div + h5, .wyswyg p + h1,
.wyswyg p + h2,
.wyswyg p + h3,
.wyswyg p + h4,
.wyswyg p + h5, .wyswyg ul + h1,
.wyswyg ul + h2,
.wyswyg ul + h3,
.wyswyg ul + h4,
.wyswyg ul + h5 {
	margin-top: 16px;
}

@media (--laptop) {
	.wyswyg h1, .wyswyg h2, .wyswyg h3, .wyswyg h4, .wyswyg h5, .wyswyg h6 {
		padding-bottom: 16px;
	}
	.wyswyg h1 {
		font-size: 32px;
		line-height: 46px;
	}
	.wyswyg h2 {
		font-size: 26px;
		line-height: 32px;
	}
	.wyswyg h3, .wyswyg h4 {
		font-size: 20px;
		line-height: 24px;
	}
	.wyswyg h5 {
		font-size: 18px;
		line-height: 24px;
	}
	.wyswyg div + h1,
	.wyswyg div + h2,
	.wyswyg div + h3,
	.wyswyg div + h4,
	.wyswyg div + h5, .wyswyg p + h1,
	.wyswyg p + h2,
	.wyswyg p + h3,
	.wyswyg p + h4,
	.wyswyg p + h5, .wyswyg ul + h1,
	.wyswyg ul + h2,
	.wyswyg ul + h3,
	.wyswyg ul + h4,
	.wyswyg ul + h5 {
		margin-top: 40px;
	}
}

.header {
	position: relative;
	z-index: 1000;
	width: 100%;
	height: 70px;
	background-color: #414142;
}

.header__box {
	position: relative;
	height: 70px;
}

.header__link {
	width: 118px;
	display: block;
}

.header__logo {
	font-size: 0;
	line-height: 0;
}

.header__logo img {
	display: block;
}

.header__drop {
	position: absolute;
	width: 100%;
	top: 100%;
	max-height: 0;
	overflow: hidden;
	display: block;
	transition: max-height 0.3s ease-out;
}

.header__drop--active {
	max-height: 600px;
}

.header__burger {
	position: absolute;
	top: 50%;
	right: 0;
	width: 100%;
	height: 5px;
	border-radius: 8px;
	background-color: #FFFFFF;
	opacity: 1;
	transform: translateY(-50%);
	transition: all .3s ease-in-out;
}

.header__burger:before, .header__burger:after {
	position: absolute;
	width: 32px;
	height: 5px;
	border-radius: 8px;
	display: block;
	background-color: #FFFFFF;
	content: "";
	transition: all .3s ease-in-out;
}

.header__burger:before {
	transform: translateY(10px);
}

.header__burger:after {
	transform: translateY(-10px);
}

.header__burger-box {
	position: relative;
	width: 32px;
	height: 28px;
	display: inline-block;
}

.header__burger-button {
	position: absolute;
	right: 0;
	top: 50%;
	width: 32px;
	height: 28px;
	cursor: pointer;
	transform: translateY(-50%);
}

.header__burger--open .header__burger {
	background-color: transparent;
}

.header__burger--open .header__burger:before {
	top: 0;
	transform: rotate(45deg) translateY(0);
}

.header__burger--open .header__burger:after {
	bottom: 0;
	transform: rotate(-45deg) translateY(0);
}

@media (--tablet) {
	.header__link {
		width: initial;
	}
	.header__logo {
		position: absolute;
		top: 0;
		left: 0;
	}
	.header__logo img {
		width: 210px;
		height: 173px;
	}
	.header__drop, .header__burger-button {
		display: none;
	}
}

.footer__content {
	padding: 20px 0;
	border-top: 1px solid #A7A7A7;
	color: #9e9e9e;
	font-size: 13px;
	line-height: 18px;
}

.footer__title {
	text-transform: uppercase;
}

@media (--tablet) {
	.footer__box {
		margin: 0 auto;
		width: 460px;
		display: flex;
	}
	.footer__text {
		margin-left: 20px;
	}
}

.menu {
	position: relative;
}

.menu__item {
	border-bottom: 1px solid #6f6f6f;
}

.menu__item.current-menu-item .menu__link {
	background: url(assets/images/menu_active.png) repeat;
	font-weight: 700;
}

.menu__item.current-menu-item.menu-item-has-children .menu__submenu {
	display: block;
}

.menu__item.menu-item-has-children .menu__submenu {
	display: none;
}

.menu__item.current-menu-parent .menu__link {
	background: url(assets/images/menu_active.png) repeat;
	font-weight: 700;
}

.menu__item.current-menu-parent .menu__submenu {
	display: block;
}

.menu__link {
	font-size: 16px;
}

.menu__link:hover {
	font-weight: 700;
}

.menu__submenu-link--active {
	background: url(assets/images/menu_active.png) repeat;
	font-weight: 700;
}

.menu__submenu li {
	border-bottom: 1px solid #6f6f6f;
}

.menu__submenu li a {
	padding-left: 20px;
}

.menu__submenu a {
	font-size: 12px;
}

.menu__submenu a:hover {
	font-weight: 700;
}

.menu li {
	background-color: #414142;
}

.menu li:hover > a {
	background: url(assets/images/menu_active.png) repeat;
}

.menu a {
	padding: 7px 10px 7px 27px;
	display: block;
	color: #FFFFFF;
}

@media (--tablet) {
	.menu {
		width: 220px;
	}
	.menu:before {
		position: relative;
		width: 100%;
		height: 94px;
		display: block;
		background-color: #414142;
		content: '';
	}
	.menu:after {
		position: relative;
		width: 100%;
		height: 66px;
		display: block;
		background: url("assets/images/sidebar_bottom.png") no-repeat center;
		background-size: contain;
		content: '';
	}
}

.article {
	padding: 0 20px;
}

.article__main {
	margin-left: 10px;
}

.article__box {
	padding-top: 20px;
	display: flex;
}

.article__content--documents {
	text-align: justify;
}

.article__date {
	padding-top: 10px;
	border-top: 1px solid #A7A7A7;
	color: #414142;
	font-size: 13px;
	line-height: 18px;
}

.article__single-title {
	padding-bottom: 20px;
	color: #414142;
	font-size: 21px;
	line-height: 28px;
}

.article__single-image img {
	display: block;
}

.article__subtitle {
	color: #414142;
	font-size: 13px;
	font-weight: 700;
	line-height: 18px;
}

.article__contacts {
	margin-top: 8px;
	text-align: center;
}

.article__map {
	margin-top: 60px;
}

.article__news-item {
	padding: 10px 0;
	color: #414142;
	font-size: 13px;
	line-height: 18px;
}

.article__news-item strong {
	font-weight: 700;
}

.article__news-item a {
	color: #f71a22;
}

.article__news-item + .article__news-item {
	border-top: 1px solid #A7A7A7;
}

.article__group-title {
	margin-top: 24px;
	color: #414142;
	font-size: 18px;
	font-weight: 600;
	text-align: center;
}

.article__group + .article__group .article__group-title {
	margin-top: 48px;
}

.article__group-list {
	margin-top: 48px;
}

.article__group-item {
	display: flex;
	align-items: center;
}

.article__group-item + .article__group-item {
	margin-top: 48px;
}

.article__group-image {
	width: 136px;
}

.article__group-content {
	margin-left: 10px;
	flex: 1;
}

.article__group-content h5,
.article__group-content p {
	color: #414142;
	font-size: 13px;
	line-height: 18px;
}

.article__group-content h5 {
	font-weight: 600;
}

.article__group-content h5 + p {
	margin-top: 20px;
}

.article__map {
	position: relative;
	width: 100%;
	height: 360px;
}

.article--member .article__main {
	margin-left: 0;
}

.article--member .article__image {
	max-width: 170px;
}

@media (--laptop) {
	.article {
		padding: 0;
	}
}

.page-jaunumi .article__title {
	margin-bottom: 0;
}

.page-template-current-event-template .article__title {
	padding-bottom: 8px;
}

.pagination__list {
	display: flex;
	justify-content: center;
}

.pagination__item a {
	padding: 0.5em;
	display: inline-block;
	color: #f71a22;
	font-size: 13px;
	line-height: 18px;
}

.pagination__item--disabled {
	display: none;
}

.pagination__item--current a {
	color: #414142;
	font-weight: 700;
}

.pagination__item + .pagination__item {
	margin-left: 24px;
}

.ie {
	display: block;
}
