ul, ol {
	position: relative;
	margin: 16px 0 16px 1.2em;

	li {
		position: relative;
		color: $color__dark;
		font-family: $font-primary;
		font-size: 13px;
		line-height: 18px;

		&:before {
			position: absolute;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	ol {
		margin: 8px 0 8px 24px;
	}
}

ul {
	list-style: disc;

	li {
		&:before {
			left: -24px;
		}
	}
}

ol {
	list-style: none;

	li {
		&:before {
			left: -18px;
		}
	}
}

ol,
ul > ol {
	counter-reset: section; /* Creates a new instance of the section counter with each ol element */
	list-style-type: none;

	li {
		&:before {
			counter-increment: section; /* Increments only this instance of the section counter */
			content: counters(section, ".") "."; /* Combines the values of all instances of the section counter, separated by a period */
			width: 16px;
			font-weight: 700;
			color: $color__dark;
		}
	}
}

li > ul,
li > ol {
	margin-bottom: 0;
}

li > ul {
	margin-left: 34px;
}

li > ol {
	margin-left: 34px;

	li:before {
		left: -24px;
	}
}

dt, dd {
	font-family: $font-primary;
	color: $color__dark;
	line-height: 18px;
}

dt {
	padding-bottom: 8px;
	font-size: 16px;
}

dd {
	padding-bottom: 16px;
	font-size: 13px;
}